//====================================================
//  Function: Slider
//====================================================
function fn_components_slider(){
	fn_hero_slider();
	fn_classic_slider();
}

function fn_classic_slider(){
	if ($( '.js-slider-classic' ).length) {
		$( '.js-slider-classic' ).slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			// cssEase: 'linear',
			speed: 500,
			variableWidth: false,
			prevArrow: '<span class="slick-arrows slick-prev"><svg class="ico-svg icon-arrow-reference-left"><use xlink:href="#icon-arrow-reference-left"> </span>',
			nextArrow: '<span class="slick-arrows slick-next"><svg class="ico-svg icon-arrow-reference-right"><use xlink:href="#icon-arrow-reference-right"> </span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1220,
					settings: {
						arrows: true,
					}
				},
				{
					breakpoint: 992,
					settings: {
						arrows: true,
					}
				},
				{
					breakpoint: 772,
					settings: {
						arrows: true,
					}
				},
				{
					breakpoint: 560,
					settings: {
						arrows: false,
					}
				},
				{
					breakpoint: 460,
					settings: {
						arrows: false,
					}
				},
				{
					breakpoint: 200,
					settings: {
						arrows: false,
					}
				}
			]
		});
	}
}

function fn_hero_slider(){
	if ($( '.js-slider-hero-text' ).length) {
		$( '.js-slider-hero-text' ).slick({
			infinite: false,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			speed: 500,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon-next"><svg class="ico-svg icon-arrow-r"><use xlink:href="#icon-arrow-r"></span> </span>',
			prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon-prev"><svg class="ico-svg icon-arrow-l"><use xlink:href="#icon-arrow-l"> </span> </span>',
			mobileFirst: true,
			fade: true,
			asNavFor:  '.js-slider-hero-image' 
		});

		$( '.js-slider-hero-image' ).slick({
			infinite: false,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			autoplaySpeed: 3000,
			speed: 500,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-next"><span class="slick-icon-next"><svg class="ico-svg icon-arrow-r"><use xlink:href="#icon-arrow-r"></span> </span>',
			prevArrow: '<span class="slick-arrows slick-prev"><span class="slick-icon-prev"><svg class="ico-svg icon-arrow-l"><use xlink:href="#icon-arrow-l"> </span> </span>',
			mobileFirst: true,
			fade: true,
			asNavFor:  '.js-slider-hero-text' 
		});
	}
}
