/* ==========================================================================
 *  Initialization Google maps
 * ======================================================================= */

function fn_components_google_maps() {
	$(".map-block").each(function() {
		var inst = $(this);
		var mapId = inst.attr("id");
		var langitude = inst.data("langitude");
		var longitude = inst.data("longitude");
		fn_init_map(mapId, langitude, longitude);
	});
}

var mapOptions = {
	zoom: 11,
	zoomControl: true,
	disableDoubleClickZoom: false,
	mapTypeControl: false,
	scaleControl: true,
	scrollwheel: false,
	panControl: true,
	streetViewControl: false,
	draggable: true,
	overviewMapControl: false,
	overviewMapControlOptions: {
		opened: false
	},
	styles: [{featureType:"landscape",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"poi",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"labels.icon",stylers:[{visibility:"off"}]},{stylers:[{hue:"#00aaff"},{saturation:-100},{gamma:2.15},{lightness:12}]},{featureType:"road",elementType:"labels.text.fill",stylers:[{visibility:"on"},{lightness:24}]},{featureType:"road",elementType:"geometry",stylers:[{lightness:57}]}]
	// mapTypeId: google.maps.MapTypeId.ROADMAP
};

var mapContactElement;
var mapContact;
var mapContactBounds;

function fn_init_map(id, langitude, longitude) {
	mapContactElement = document.getElementById(id)
	mapContact = new google.maps.Map(mapContactElement, mapOptions);
	mapContact.setCenter(new google.maps.LatLng(langitude, longitude));


	mapContactBounds = new google.maps.LatLngBounds();

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	function markerSet(image, width, height){
		return new google.maps.MarkerImage(
			image,
			new google.maps.Size(width, height),
			// The origin for my image is 0,0.
			new google.maps.Point(0, 0),
			// The center of the image is 50,50 (my image is a circle with 100,100)
			new google.maps.Point(width / 2, height / 2)
		);
	}

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
		var markerImg = markerSet('images/map-marker.png', 37, 37);
	} else {
		var markerImg = markerSet('images/map-marker.svg', 37, 37);
	}

	var locations = [[langitude, longitude, markerImg]];

	var mapWindow = '<div class="map-content"><span class="map-title">TrustWire s.r.o.</span><p>Véska 8 – 783 16 Dolany</p></div>';
	
	for (i = 0; i < locations.length; i++) {
		

		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(locations[i][0], locations[i][1]),
			draggable: false,
			icon: markerImg,
			name: mapWindow
		});

		var infoWindow = new google.maps.InfoWindow({
			maxWidth: 220,
			pixelOffset: new google.maps.Size(140, 60)
		}), marker, i;

		google.maps.event.addListener(marker, 'click', function(e){
			// infoWindow.open(mapContact, this);
		});

		infoWindow.setContent(marker.name);
		infoWindow.open(mapContact, marker);
		$('.gm-style-iw').parent().addClass('window-block');


		mapContactBounds.extend(marker.position);
		marker.setMap(mapContact);
	}

}

var mapListElement;
var mapList;
var mapListGmarkers;
var mapListBounds;

function fn_init_list(id, langitude, longitude) {
	mapListElement = document.getElementById(id)
	mapList = new google.maps.Map(mapListElement, mapOptions);
	mapList.setCenter(new google.maps.LatLng(langitude, longitude));

	mapListGmarkers = [];
	mapListBounds = new google.maps.LatLngBounds();

	var markers = [{
		"id": "1",
		"longitude": 50.091922,
		"latitude": 14.423461,
		"link": "retreat-venues-detail.html",
		"image": [
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg"
		],
		"rate": [
			"full",
			"full",
			"full",
			"half",
			"empty"
		],
		"rate_value": "24",
		"title": "Cottage in wood Cottage in wood Cottage in wood",
		"price": "520"
	}, {
		"id": "2",
		"longitude": 50.090564,
		"latitude": 14.413077,
		"link": "retreat-venues-detail.html",
		"image": [
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg"
		],
		"rate": [
			"full",
			"full",
			"full",
			"half",
			"empty"
		],
		"rate_value": "24",
		"title": "Cottage in wood Cottage in wood Cottage in wood",
		"price": "520"
	}, {
		"id": "3",
		"longitude": 50.090802,
		"latitude": 14.463077,
		"link": "retreat-venues-detail.html",
		"image": [
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg"
		],
		"rate": [
			"full",
			"full",
			"full",
			"half",
			"empty"
		],
		"rate_value": "24",
		"title": "Cottage in wood Cottage in wood Cottage in wood",
		"price": "520"
	}, {
		"id": "4",
		"longitude": 50.110702,
		"latitude": 14.403077,
		"link": "retreat-venues-detail.html",
		"image": [
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg"
		],
		"rate": [
			"full",
			"full",
			"full",
			"half",
			"empty"
		],
		"rate_value": "24",
		"title": "Cottage in wood Cottage in wood Cottage in wood",
		"price": "520"
	}, {
		"id": "5",
		"longitude": 48.212535,
		"latitude": 19.335488,
		"link": "retreat-venues-detail.html",
		"image": [
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg"
		],
		"rate": [
			"full",
			"full",
			"full",
			"half",
			"empty"
		],
		"rate_value": "24",
		"title": "Cottage in wood Cottage in wood Cottage in wood",
		"price": "520"
	}, {
		"id": "6",
		"longitude": 40.212535,
		"latitude": 26.335488,
		"link": "retreat-venues-detail.html",
		"image": [
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg",
			"images/retreat-venues-item-1.jpg"
		],
		"rate": [
			"full",
			"full",
			"full",
			"half",
			"empty"
		],
		"rate_value": "24",
		"title": "Cottage in wood Cottage in wood Cottage in wood",
		"price": "520"
	}]


	updateMapMarkers(markers);

	mapList.fitBounds(mapListBounds);

	var mapSearchGroup = $('.js-map-search-group');
	var mapSearchBtn = $('.js-map-search-btn');
	var mapSearchCheckbox = $('.js-map-search-checkbox');

	google.maps.event.addListener(mapList, 'dragend', function() {
		if (mapSearchCheckbox.prop('checked') == false){
			mapSearchGroup.addClass('is-moved');
		} else {
			mapSearchGroup.removeClass('is-moved');
			updateMapMarkers(markers);
			callbackMoveMap(mapList);
		}
	});

	mapSearchBtn.bind( 'click' , function(e) {
		mapSearchGroup.removeClass('is-moved');
		updateMapMarkers(markers);
		callbackMoveMap(mapList);
	});

}


function updateMapMarkers(markers){

	var objects = [];
	var imagesSlider = [];
	var rateList = [];

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	function markerSet(image, width, height){
		return new google.maps.MarkerImage(
			image,
			new google.maps.Size(width, height),
			// The origin for my image is 0,0.
			new google.maps.Point(0, 0),
			// The center of the image is 50,50 (my image is a circle with 100,100)
			new google.maps.Point(width / 2, height)
		);
	}

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
		var markerImg = markerSet('images/map-marker.png', 32, 37);
		var markerImgSelect = markerSet('images/map-marker-select.png', 32, 37);
	} else {
		var markerImg = markerSet('images/map-marker.svg', 32, 37);
		var markerImgSelect = markerSet('images/map-marker-select.svg', 32, 37);
	}

	for (var i = 0; i < markers.length; ++i) {

		for (var j = 0; j < markers[i].image.length; ++j) {
			imagesSlider.push('<div class="js-slide-item"><a href="'+ markers[i].link +'" class="card-image-block"><img src="'+ markers[i].image[j] +'" alt="" loading="lazy" class="img-responsive img-full img-cover"></a></div>');
		}
		
		for (var j = 0; j < markers[i].rate.length; ++j) {
			rateList.push('<li class="rate-list-item js-rate-list-item"><svg class="ico-svg icon-star-' + markers[i].rate[j] + '"><use xlink:href="images/sprites.svg#icon-star-' + markers[i].rate[j] + '"></use></svg></li>');
		}

		var template = '<div class="card-group card-group-small card-group-map h-margin-08">' +
							'<div class="card-item", data-id="' + markers[i].id + '">' +
								'<div class="card-content">' +
									'<div class="card-slider-block">' +
										'<div class="js-slider js-slider-classic-arrows-map">' + imagesSlider.join(' ') + '</div>' +
										'<div class="card-action card-action-right card-favourite">' +
											'<svg class="ico-svg icon-favourite-disabled"><use xlink:href="images/sprites.svg#icon-favourite-disabled"></use></svg>' + 
										'</div>' +
										'<div class="card-action card-action-left card-premium">' +
											'<svg class="ico-svg icon-star-empty-white"><use xlink:href="images/sprites.svg#icon-star-empty-white"></use></svg>' + 
										'</div>' +
									'</div>' +
									'<a href="'+ markers[i].link +'" class="card-text-block">' +
										'<div class="rate-block rate-block-small">' +
											'<ul class="rate-list">' + rateList.join(' ') + '</ul>' +
										'</div>' +
										'<h3 class="card-title epsilon js-dots" data-height-short="30">' + markers[i].title + '</h3>' +
										'<div class="card-price">' +
											'from <strong>'+ markers[i].price +'</strong> / night' +
										'</div>' +
									'</a>' +
								'</div>' +
							'</div>' +
						'</div>'
		objects.push(template);
		imagesSlider = [];
		rateList = [];
	}


	for (var i = 0; i < markers.length; ++i) {
		var latLng = new google.maps.LatLng(markers[i].longitude, markers[i].latitude)

		var marker = new google.maps.Marker({
			position: latLng,
			draggable: false,
			icon: markerImg,
			active: markerImgSelect,
			id: markers[i].id,
			name: objects[i]
		});

		var infoWindow = new google.maps.InfoWindow({
			maxWidth: 270
		}), marker, i;

		google.maps.event.addListener(marker, 'click', function(e){

			if (this.id.length){
				for (var j = 0; j < mapListGmarkers.length; j++) {
					mapListGmarkers[j].setIcon(this.icon);
				}
				infoWindow.setContent(this.name);
				this.setIcon(this.active);
				infoWindow.open(mapList, this);
				$('.gm-style-iw').parent().addClass('window-block');
		
			}
		});

		google.maps.event.addListener(infoWindow,'closeclick',function(){
			for (var j = 0; j < mapListGmarkers.length; j++) {
				mapListGmarkers[j].setIcon(markerImg);
			}
		});

		mapListBounds.extend(marker.position);
		marker.setMap(mapList);
		mapListGmarkers.push(marker);
	}
}

function callbackMoveMap(mapList){
	alert("New map bounds: " + mapList.getBounds())
	console.log("New map bounds: " + mapList.getBounds());
}