// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $('.header');
var bp_small = 771;
var bp_medium = 992;
var bp_large = 1200;
var class_active = 'is-active';
var class_disabled = 'is-disabled';
var class_last = 'is-last';
var class_has_error = 'has-error';
var class_loading = 'is-loading';
var class_opened = 'is-opened';
var class_selected = 'is-selected';
var class_collapse = 'is-collapse';
var class_animated = 'is-animated';
var $this = null;
var $containers = $('html,body');

// --- COMPONENTS, PARTS --- //
$(document).ready(function() {
	// ---  COMPONENTS --- //
	fn_components_slider();
	fn_components_gallery();
	fn_components_tabs();
	fn_components_aos();
	fn_components_fullscreen_navigation();
	fn_components_validate_forms();
	fn_components_textareaResize();
	fn_components_google_maps();
	// fn_components_mix_it_up();
	// fn_components_counter();

	// ---  PARTS --- //
	fn_parts_placeholder();
	fn_parts_scroll_to();
	fn_parts_scroll_resize();
});

var resizeTimer;

// --- SCROLL EVENT --- //
$(document).scroll(function() {
	if (resizeTimer) {
		window.cancelAnimationFrame(resizeTimer);
	}

	resizeTimer = window.requestAnimationFrame(function () {
		fn_parts_scroll_resize();
	});
});

// --- RESIZE EVENT --- //
$(window).resize(function() {
	if (resizeTimer) {
		window.cancelAnimationFrame(resizeTimer);
	}

	resizeTimer = window.requestAnimationFrame(function () {
		fn_parts_scroll_resize();
	});
});

// --- LOAD EVENT --- //
$(window).bind('load', function() {
	fn_parts_dots();

	// pageLoader
	$('body').addClass(class_active);

	if ($('.no-object-fit').length){
		fitImg('.img-cover');
	}

	function fitImg(imgClass){
		$(imgClass).each(function(){
		    var imgElement = $(this),
		        imgSrc = 'url(' + imgElement.attr('src') + ')',
		        imgParent = imgElement.parent(),
		        imgNewElement = $('<div></div>');
		    imgElement.hide();
		    imgParent.append(imgNewElement);
		    imgNewElement.css({
		        'height'                : '100%',
		        'background-repeat'     : 'no-repeat',
		        'background-position'   : 'center',
		        'background-size'   : 'cover',
		        'width': '100%',
		        'background-image'      : imgSrc
		    });
		    imgNewElement.addClass('ie-img');
		});
	}
});
