//====================================================
//  Function: Items filtering
//====================================================
function fn_components_mix_it_up(){
	if ($( '.js-portfolio-group' ).length) {
        var containerEl = document.querySelector('.js-portfolio-group');
        var mixer = mixitup(containerEl, {
			animation: {
				applyPerspective: false,
				easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
				animateResizeContainer: false,
				animateResizeTargets: true,
				clampHeight: false,
				clampWidth: false
			}
		});

        $( document ).on('click', '.js-portfolio-filter-list-item', function(e){
			$( '.js-portfolio-filter-list-item' ).removeClass( class_active );
			$( this ).addClass( class_active );
		});
	}
}
